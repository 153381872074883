<template>
  <div class="partners-list">
    <div class="clients">
      <img id="forbes" class="client" src="~/assets/imgs/partners/forbes.webp" alt="Forbes" loading="lazy" width="70" height="40" />
      <img id="bfm" class="client" src="~/assets/imgs/partners/bfm.webp" alt="BFMTV" loading="lazy" width="37.5" height="30" />
      <img
        id="challenges"
        class="client"
        src="~/assets/imgs/partners/challenges.webp"
        alt="Challenges"
        loading="lazy"
        width="93" height="24"
      />
      <img
        id="capital"
        class="client"
        src="~/assets/imgs/partners/capital.webp"
        alt="Capital"
        loading="lazy"
        width="70" height="21"
      />
      <img
        id="region"
        class="client no-mobile"
        src="~/assets/imgs/partners/region.webp"
        alt="Nouvelle Aquitaine"
        loading="lazy"
        width="99" height="26"
      />
      <img
        id="maddyness"
        class="client no-mobile"
        src="~/assets/imgs/partners/maddyness.webp"
        alt="Maddyness"
        loading="lazy"
        width="107" height="47"
      />
      <img
        id="platform"
        class="client no-mobile"
        src="~/assets/imgs/partners/platform58.webp"
        alt="Platform58"
        loading="lazy"
        width="129" height="37"
      />
      <img
        id="express"
        class="client no-mobile"
        src="~/assets/imgs/partners/express.webp"
        alt="Express"
        loading="lazy"
        width="104" height="19"
      />
      <div class="space" />
    </div>
  </div>
</template>

<style lang="less" scoped>
.partners-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.clients {
  max-width: 100%;
  height: 60px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-self: center;
  align-items: center;
  gap: 15px;
  filter: contrast(0.3);

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .generate-delay(6);
  .generate-delay(@n, @i: 1) when (@i =< @n) {
    .client:nth-child(@{i}) {
      transition-delay: (@i * 70ms);
    }
    .generate-delay(@n, (@i + 1));
  }
  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.8;
  }

  .client {
    width: auto;
  }

  #forbes {
    height: 40px;
  }
  #bfm {
    height: 30px;
  }
  #challenges {
    height: 24px;
  }
  #capital {
    height: 21px;
  }

  .no-mobile {
    display: none;
  }

  .space {
    height: 100%;
    min-width: 1px;
  }
}

@media @bp-desktop {
  .clients {
    height: 120px;

    img:not(.last) {
      margin-right: 60px;
    }

    #forbes {
      height: 47px;
    }
    #bfm {
      height: 37px;
    }
    #challenges {
      height: 31px;
    }
    #capital {
      height: 28px;
    }
    #region {
      height: 56px;
    }
    #maddyness {
      height: 47px;
    }
    #platform {
      height: 37px;
    }
    #express {
      height: 19px;
    }
    .no-mobile {
      display: block;
    }
  }
}
</style>
